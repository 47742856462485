<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap">
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.cityId" clearable placeholder="市" @change="handleCity">
              <el-option v-for="(item,index) in city" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.countyId" clearable placeholder="区县" @change="handleCounty">
              <el-option v-for="(item,index) in county" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.businessId" clearable placeholder="商圈">
              <el-option v-for="(item,index) in business" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.name" placeholder="输入楼盘名称" class="marginRight10 width300 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <!--        查看-->
        <el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)">查看</el-button>
        <!--        审核-->
        <el-button size="mini" type="text" v-if="row.applyState === 2" icon="el-icon-connection" @click="handleExamine(row)">审核</el-button>
      </template>
    </avue-crud>

    <Examine v-if="show" :show="show" :examine="examine" @submit="submitExamine" @close="closeExamine"/>
  </div>
</template>

<script>
import { option } from './data'
import { listBusiness, listCity, listCounty} from "@/api/house/second";
import { list } from "@/api/house/tenancyExamine";
import Examine from "./examine";
export default {
  name: "index",
  components: {
    Examine
  },
  data(){
    return{
      city: [],
      county: [],
      business: [],
      searchData: {
        cityId: '',
        countyId: '',
        businessId: '',
        name: '',
        tradeState: '',
        state: '',
        isVr: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: option,

      show: false,
      examine: {
        id: ''
      }
    }
  },
  async mounted() {
    await this.getListCity();
  },
  methods: {
    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.city = data || [];
    },
    async getListCounty(id){
      const { data } = await listCounty({
        id: id
      });
      this.county = data || [];
    },
    async getListBusiness(id){
      const { data } = await listBusiness({
        id: id
      });
      this.business = data || [];
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 选择市
    async handleCity(e){
      this.searchData.countyId = '';
      this.searchData.businessId = '';
      this.county = [];
      this.business = [];
      await this.getListCounty(e);
    },
    // 选择区县
    async handleCounty(e){
      this.searchData.businessId = '';
      this.business = [];
      await this.getListBusiness(e);
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        cityId: '',
        countyId: '',
        businessId: '',
        name: ''
      }
      this.county = [];
      this.business = [];
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 查看
    handleView(row){
      // console.log('查看',row)
      this.$router.push({
        path: '/house/tenancyExamine/detail',
        query: {
          id: row.id
        }
      })
    },
    // 审核
    handleExamine(row){
      // console.log('审核',row)
      this.show = true;
      this.examine = {
        id: row.id
      }
    },
    submitExamine(){
      this.show = false;
      this.onLoad();
    },
    closeExamine(){
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>